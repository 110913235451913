import React from "react";
import ReactDOM from "react-dom";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
  }

  handleChange(event) {
    let loginInput = document.querySelector("#loginInput");
    if (!loginInput) { return; }

    if (Array.from(loginInput.querySelectorAll("input")).every(e => e.validity.valid)) {
      loginInput.querySelector(".modal-login-btn").classList.add("ok");
    } else {
      loginInput.querySelector(".modal-login-btn").classList.remove("ok");
    };
  };

  handleKeyDown(event) {
    console.log("handleKeyDown");
    if (event.keyCode === 13) {
      console.log("handleKeyDown: Submit");
      let loginInput = document.querySelector("#loginInput");
      if (!loginInput) { return; }
      if (!Array.from(loginInput.querySelectorAll("input")).every(e => e.validity.valid)) { return; }

      loginInput.closest("form").submit();
    };
  };

  handleSubmit(event) {
    event.preventDefault();
    let loginInput = document.querySelector("#loginInput");
    if (!loginInput) { return; }
    if (!Array.from(loginInput.querySelectorAll("input")).every(e => e.validity.valid)) { return; }

    loginInput.closest("form").submit();
  }

  componentDidMount() {
    console.log("mount");
    Array.from(document.querySelector("#loginInput").querySelectorAll("input")).forEach((input) => {
      input.addEventListener("keydown", this.handleKeyDown);
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-lg-10 offset-lg-1">
          <h2>ログイン</h2>
          <input className="form-control mb-3" placeholder="メールアドレス" required="required" type="email" name="email" onChange={this.handleChange} />
          <input className="form-control mb-3" placeholder="パスワード" required="required" type="password" name="password" onChange={this.handleChange} />
        </div>
        <div className="col-lg-10 offset-lg-1 text-center mb-2 modal-login-box">
          <div className="modal-login-btn">
            <a id="loginSubmit" onClick={this.handleSubmit}>
              <p>ログイン</p>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

document.addEventListener("DOMContentLoaded", () => {
  let loginForm = document.querySelector("#loginInput");
  if (loginForm) {
    ReactDOM.render(
      <LoginForm />,
      loginForm
    );
  }
});
